import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import {
  Banner,
  Box,
  Button,
  ErrorBanner,
  RadioButton,
  Table,
  P,
  Modal,
  Tabs,
  useI18nContext,
} from '@procore/core-react';
import styled from 'styled-components';
import { PERMISSION_LEVELS } from './permissions_constants';

const CenteredRadio = styled(RadioButton)`
  justify-content: center;
`;

const PermissionsModalBody = styled(Modal.Body)`
  flex: 1;
  margin: 0;
  max-width: none;
  min-width: 575px;
  width: 50vw;
`;

const isLevelActive = (level, permission = null) => {
  if (permission === null && level === 'none') {
    return true;
  }
  return permission === level;
};

const PermissionsBuilder = ({
  disabled,
  hasErrors,
  injectPermissions,
  clientCredentialsComponent,
  validating,
  dmsaPermissions,
}) => {
  // Component state
  const I18n = useI18nContext();
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState({
    company: clientCredentialsComponent?.permissions?.company || {},
    project: clientCredentialsComponent?.permissions?.project || {},
  });
  const [sortedPermissions, setSortedPermissions] = useState(
    dmsaPermissions.company
  );
  const [currentSort, setCurrentSort] = useState('tool');
  const [currentSortDirection, setCurrentSortDirection] = useState('asc');
  const [error, setError] = useState(false);
  const [tab, setTab] = useState('company');

  const handlePermissionsLevelChange = (permissionName, permissionsLevel) => {
    if (permissionsLevel === 'none') {
      delete selectedPermissions[tab][permissionName];
    } else {
      selectedPermissions[tab][permissionName] = permissionsLevel;
    }
    setSelectedPermissions({
      ...selectedPermissions,
    });
  };

  const handleInjectPermissions = () => {
    injectPermissions(selectedPermissions);
    setPermissionsModalOpen(false);
  };

  const sortPermissions = () => {
    const orderPermissionsByAlphabet = (a, b) => {
      const permissionA = a.friendly_name.toUpperCase();
      const permissionB = b.friendly_name.toUpperCase();
      // Always sort asc alphabetically if sorting by permission level
      if (permissionA < permissionB) {
        return currentSortDirection === 'asc' || currentSort !== 'tool'
          ? -1
          : 1;
      }
      if (permissionA > permissionB) {
        return currentSortDirection === 'asc' || currentSort !== 'tool'
          ? 1
          : -1;
      }
      return 0;
    };

    if (currentSort === 'tool') {
      setSortedPermissions(() => {
        return dmsaPermissions[tab].sort((a, b) =>
          orderPermissionsByAlphabet(a, b)
        );
      });
    } else {
      setSortedPermissions(() => {
        return dmsaPermissions[tab].sort((a, b) => {
          const aLevel = selectedPermissions[tab][a.name] || 'none';
          const bLevel = selectedPermissions[tab][b.name] || 'none';
          if (aLevel === bLevel) {
            // Sort permissions by name if levels are equal
            return orderPermissionsByAlphabet(a, b);
          }
          if (aLevel === currentSort) {
            return currentSortDirection === 'asc' ? -1 : 1;
          }
          if (bLevel === currentSort) {
            return currentSortDirection === 'asc' ? 1 : -1;
          }
          return 0;
        });
      });
    }
  };

  const handleSortChange = (column, resort) => {
    if (column === currentSort && !resort) {
      setCurrentSortDirection(currentSortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setCurrentSort(column);
      setCurrentSortDirection('asc');
    }
  };

  useEffect(() => {
    setSelectedPermissions({
      company: clientCredentialsComponent?.permissions?.company || {},
      project: clientCredentialsComponent?.permissions?.project || {},
    });
  }, [permissionsModalOpen]);

  useEffect(() => {
    sortPermissions();
  }, [currentSortDirection, currentSort, tab]);

  return (
    <>
      <Button
        data-testid="open-permissions-builder-button"
        disabled={disabled}
        variant="secondary"
        size="sm"
        onClick={() => setPermissionsModalOpen(true)}
      >
        {I18n.t('openBuilder')}
      </Button>
      <Modal
        open={permissionsModalOpen}
        onClickOverlay={() => {
          setPermissionsModalOpen(false);
        }}
      >
        <Modal.Header
          onClose={() => {
            setPermissionsModalOpen(false);
          }}
        >
          {I18n.t('permissionBuilder')}
        </Modal.Header>
        <PermissionsModalBody>
          <Box>
            <P>{I18n.t('selectTheLevel')}</P>
          </Box>
          {error && (
            <Box paddingBottom="md">
              <ErrorBanner>
                <Banner.Content>
                  <Banner.Title>{I18n.t('error')}</Banner.Title>
                  <Banner.Body>{error}</Banner.Body>
                </Banner.Content>
                <Banner.Dismiss onClick={() => setError(false)} />
              </ErrorBanner>
            </Box>
          )}
          <Box paddingBottom="md">
            <Tabs>
              <Tabs.Tab active={tab === 'company'}>
                <Tabs.Link onClick={() => setTab('company')}>
                  {I18n.t('company')}
                </Tabs.Link>
              </Tabs.Tab>
              <Tabs.Tab active={tab === 'project'}>
                <Tabs.Link
                  onClick={() => setTab('project')}
                  data-testid="switch-permissions-to-project"
                >
                  {I18n.t('project')}
                </Tabs.Link>
              </Tabs.Tab>
            </Tabs>
          </Box>
          <Table.Container>
            <Table>
              <Table.Header>
                <Table.HeaderRow>
                  <Table.HeaderCell
                    data-testid="sort-by-tool"
                    sortable
                    onClick={() => handleSortChange('tool')}
                    variant={currentSort === 'tool' ? currentSortDirection : ''}
                  >
                    {I18n.t('tool')}
                  </Table.HeaderCell>
                  {PERMISSION_LEVELS.map(({ name }) => (
                    <Table.HeaderCell
                      data-testid={`sort-by-${name}`}
                      key={`header-${name}`}
                      style={{ width: '20%' }}
                      sortable
                      onClick={() => handleSortChange(name)}
                      variant={currentSort === name ? currentSortDirection : ''}
                    >
                      {I18n.t(name)}
                    </Table.HeaderCell>
                  ))}
                </Table.HeaderRow>
              </Table.Header>
              <Table.Body>
                {sortedPermissions.map(
                  ({ name, friendly_name, disabled_uals }) => (
                    <Table.BodyRow key={`${name}`}>
                      <Table.BodyCell>
                        <Table.TextCell data-testid={`permission-${name}-row`}>
                          {friendly_name}
                        </Table.TextCell>
                      </Table.BodyCell>
                      {PERMISSION_LEVELS.map(({ name: levelName, id }) => (
                        <Table.BodyCell key={`${name}-${levelName}`}>
                          <CenteredRadio
                            data-testid={`permission-level-${name}-${levelName}`}
                            disabled={disabled_uals.includes(id)}
                            checked={
                              !disabled_uals.includes(id) &&
                              isLevelActive(
                                levelName,
                                selectedPermissions[tab][name]
                              )
                            }
                            onChange={() => {
                              handlePermissionsLevelChange(name, levelName);
                            }}
                          />
                        </Table.BodyCell>
                      ))}
                    </Table.BodyRow>
                  )
                )}
              </Table.Body>
            </Table>
          </Table.Container>
          <Box marginBottom="lg" />
        </PermissionsModalBody>
        <Modal.Footer>
          <Modal.FooterButtons>
            <Button
              disabled={validating || hasErrors}
              variant="tertiary"
              onClick={() => setPermissionsModalOpen(false)}
            >
              {I18n.t('cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={handleInjectPermissions}
              data-testid="inject-permissions-button"
            >
              {I18n.t('updateManifest')}
            </Button>
          </Modal.FooterButtons>
        </Modal.Footer>
      </Modal>
    </>
  );
};

PermissionsBuilder.propTypes = {
  disabled: PT.bool.isRequired,
  hasErrors: PT.bool.isRequired,
  injectPermissions: PT.func.isRequired,
  clientCredentialsComponent: PT.shape({
    permissions: PT.shape({
      company: PT.objectOf(PT.string),
      project: PT.objectOf(PT.string),
    }),
  }).isRequired,
  validating: PT.bool.isRequired,
  dmsaPermissions: PT.shape({
    company: PT.array,
    project: PT.array,
  }),
};

export default PermissionsBuilder;
