/* eslint-disable no-unused-vars,import/no-named-as-default */
import '../polyfill';
import '../stylesheets/application.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';

/* React Components */
import ApiExplorer from '../react/api_explorer/ApiExplorer';
import TopNavNotifications from '../react/top_nav/TopNavNotifications';
import TopNavReference from '../react/top_nav/TopNavReference';
import NotificationsListPage from '../react/notifications/ListPage';
import NotificationShowPage from '../react/notifications/ShowPage';
import AdminNotificationForm from '../react/admin/notifications/NotificationForm';
import AdminNotificationsIndex from '../react/admin/notifications/IndexPage';
import AdminDeveloperAppsIndex from '../react/admin/developer_apps/index';
import AdminDevelopersIndex from '../react/admin/developers/index';
import AdminDeveloperAppsShow from '../react/admin/developer_apps/Show';
import AdminOauthApplicationsIndex from '../react/admin/oauth_applications/index';
import AdminReviewQueue from '../react/admin/developer_apps/ReviewQueue';
import AdminQuotaMinderServers from '../react/admin/quota_minder_servers/index';
import DeveloperAppsShow from '../react/developer_apps/show';
import DeveloperAppsNew from '../react/developer_apps/new';
import DeveloperAppsBetaShow from '../react/developer_apps_beta/show';
import OptInModal from '../react/developer_apps_beta/optin/modal';
import OptInBanner from '../react/developer_apps_beta/optin/banner';
import OptInForm from '../react/developer_apps_beta/optin/form';
import DeveloperAppsMetrics from '../react/shared/Metrics';
import DeleteAppCard from '../react/developer_apps/show/DeleteAppCard';
import AggregatedBlock from '../react/api_explorer/Components/Changelog/AggregatedBlock';

import autoSelectCopy from '../global_helpers/autoSelectCopy';

// Make React/Jquery Dependencies Global For Rails View Consumption
window.React = React;
window.ReactDOM = ReactDOM;
window.$ = $;
window.jQuery = $;

window.ApiExplorer = ApiExplorer;
window.AggregatedBlock = AggregatedBlock;
window.DeleteAppCard = DeleteAppCard;
window.TopNavNotifications = TopNavNotifications;
window.NotificationsListPage = NotificationsListPage;
window.NotificationShowPage = NotificationShowPage;
window.AdminNotificationForm = AdminNotificationForm;
window.AdminNotificationsIndex = AdminNotificationsIndex;
window.AdminDeveloperAppsIndex = AdminDeveloperAppsIndex;
window.AdminDeveloperAppsShow = AdminDeveloperAppsShow;
window.AdminDevelopersIndex = AdminDevelopersIndex;
window.AdminOauthApplicationsIndex = AdminOauthApplicationsIndex;
window.AdminReviewQueue = AdminReviewQueue;
window.AdminQuotaMinderServers = AdminQuotaMinderServers;
window.DeveloperAppsShow = DeveloperAppsShow;
window.DeveloperAppsNew = DeveloperAppsNew;
window.DeveloperAppsBetaShow = DeveloperAppsBetaShow;
window.OptInModal = OptInModal;
window.OptInBanner = OptInBanner;
window.OptInForm = OptInForm;
window.DeveloperAppsMetrics = DeveloperAppsMetrics;
window.TopNavReference = TopNavReference;

const jqueryUjs = require('jquery-ujs');
const dropdown = require('../global_helpers/dropdown');
const modal = require('../global_helpers/modal');
const flashFade = require('../global_helpers/flashFade');
const toggleResponse = require('../global_helpers/toggleResponse');
const quickTip = require('../global_helpers/quickTip');
const fixToTop = require('../global_helpers/fixToTop');
const topNav = require('../global_helpers/topNav');
const hoverToRevealPassword = require('../global_helpers/hoverToRevealPassword');
const dirtyFormDetector = require('../global_helpers/dirtyFormDetector');

const fixDocNav = function() {
  fixToTop.toggle(
    document.querySelector('#sticky-left-nav'),
    document.querySelector('#sticky-left-nav-trigger'),
    window
  );
};

const App = {
  init() {
    document.addEventListener('DOMContentLoaded', () => {
      hoverToRevealPassword();
      autoSelectCopy();
      fixDocNav();
      // TODO: Throttle fixDocNav on window scroll
      // https://procoretech.atlassian.net/browse/DMX-699
      $(window).scroll(() => {
        fixDocNav();
      });
      quickTip.init();
      toggleResponse.init();
      dirtyFormDetector.init();

      $('[data-behavior=modal][data-behavior-initial-open=true]').click();

      if (
        document.querySelector('[data-doc-nav-scroll]') &&
        document.querySelector('#sticky-left-nav')
      ) {
        const activeTop = $('[data-doc-nav-scroll]')
          .closest('.static-nav')
          .offset().top;
        const containerTop = $('#sticky-left-nav').offset().top;
        $('#sticky-left-nav').scrollTop(activeTop - containerTop);
      }

      if (window.location.hash && $(window.location.hash).length) {
        $('html, body').scrollTop($(window.location.hash).offset().top);
      }

      flashFade.init();
    });
  },
};

App.init();
