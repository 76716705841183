import React, { lazy, Suspense } from 'react';
import { bool, number, shape, string } from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { I18nProvider, Spinner } from '@procore/core-react';
import { BugsnagProvider } from '@/react/shared/BugsnagProvider';
import lazyWithRetry from '@/utils/lazyWithRetry';

const EndpointGroup = lazy(() =>
  lazyWithRetry(() => import('./Components/EndpointGroup/EndpointGroup'))
);

const ApiExplorer = ({ mount }) => {
  const {
    currentBranch,
    restVersion,
    isAdmin,
    isEmployee,
    isSignedIn,
    devApiUrl,
  } = mount;
  return (
    <BugsnagProvider>
      <I18nProvider>
        <Router>
          <Route path="/reference">
            <Suspense fallback={<Spinner loading />}>
              <EndpointGroup
                currentBranch={currentBranch}
                isAdmin={isAdmin}
                isEmployee={isEmployee}
                isSignedIn={isSignedIn}
                restVersion={restVersion}
                devApiUrl={devApiUrl}
              />
            </Suspense>
          </Route>
        </Router>
      </I18nProvider>
    </BugsnagProvider>
  );
};

ApiExplorer.propTypes = {
  mount: shape({
    analytics: string,
    baseUrl: string,
    currentBranch: string,
    isAdmin: bool,
    isEmployee: bool,
    isSignedIn: bool,
    restVersion: number,
  }),
};
export default ApiExplorer;
