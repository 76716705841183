import React from 'react';
import { withStateHandlers } from 'recompose';

export const withFocus = withStateHandlers(
  ({ focused = false }) => ({ focused }),
  {
    onFocus: () => () => ({ focused: true }),
    onBlur: () => () => ({ focused: false }),
  }
);

export default withFocus(({ children, ...props }) => children(props));
