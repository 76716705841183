import React, { useState } from 'react';
import PT from 'prop-types';
import Form from '@/react/shared/form';
import { MARKETPLACE_TABS } from '../StateContainer';
import { useI18nContext } from '@procore/core-react';

const CONTACT_STATE = {
  NO_SHOW: 0,
  SHOW_CONTACT_US: 1,
  SHOW_LEARN_HOW: 2,
  CREATE_LEAD: 3,
};

const getState = (fields) => {
  if (fields.create_lead) {
    return CONTACT_STATE.CREATE_LEAD;
  }
  if (fields.contact_us_email && !fields.create_lead) {
    return CONTACT_STATE.SHOW_CONTACT_US;
  }
  if (fields.learn_how_url && !fields.contact_us_email && !fields.create_lead) {
    return CONTACT_STATE.SHOW_LEARN_HOW;
  }
  return CONTACT_STATE.NO_SHOW;
};

const TabContact = ({
  fields,
  validations,
  onFieldChange,
  isProcoreEmployee,
  isDisabled,
  FormLabel,
}) => {
  const [status, setStatus] = useState(getState(fields));
  const I18n = useI18nContext();

  const onChange = (fieldPath, value) =>
    onFieldChange([MARKETPLACE_TABS.CONTACT, fieldPath], value);

  return (
    <div data-testid='marketplace-tab-contact-container'>
      <p>
        {I18n.t('labels.marketplaceApp.info.contactNote')}&nbsp;
        {I18n.t('labels.marketplaceApp.info.contactSecondNote')}&nbsp;
        {I18n.t('labels.marketplaceApp.info.contactThirdNote')}
      </p>
      <p>{I18n.t('labels.marketplaceApp.info.contactFourthNote')}</p>
      <ul>
        <li>
          {I18n.t('labels.marketplaceApp.info.contactFifthNote')}{' '}
          <a href="https://marketplace.procore.com/apps/procore-estimating">
            Procore Estimating
          </a>{' '}
          {I18n.t('labels.marketplaceApp.info.contactSixthNote')}
        </li>
        <br />
        <li>
          {I18n.t('labels.marketplaceApp.info.contactSeventhNote')}{' '}
          <a href="https://marketplace.procore.com/apps/procore-drive">
            Procore Drive
          </a>{' '}
          {I18n.t('labels.marketplaceApp.info.contactEighthNote')}
        </li>
      </ul>
      <p>{I18n.t('labels.marketplaceApp.info.contactNinthNote')}</p>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        data-qa="marketplace-app-contact-toggle"
      >
        <Form.RadioButton
          data-testid='marketplace-contact-us-button'
          checked={status === CONTACT_STATE.SHOW_CONTACT_US}
          text={I18n.t('labels.marketplaceApp.info.contactUsButton')}
          onChange={() => {
            setStatus(CONTACT_STATE.SHOW_CONTACT_US);
            onChange('create_lead', false);
          }}
        />

        <Form.RadioButton
          data-testid='marketplace-learn-how-button'
          checked={status === CONTACT_STATE.SHOW_LEARN_HOW}
          text={I18n.t('labels.marketplaceApp.info.learnHowButton')}
          onChange={() => {
            setStatus(CONTACT_STATE.SHOW_LEARN_HOW);
            onChange('create_lead', false);
          }}
        />

        {isProcoreEmployee && (
          <Form.RadioButton
            data-testid='marketplace-create-lead-button'
            checked={status === CONTACT_STATE.CREATE_LEAD}
            text={I18n.t('labels.marketplaceApp.info.createLeadButton')}
            onChange={(e) => {
              setStatus(CONTACT_STATE.CREATE_LEAD);
              onChange('create_lead', e.target.checked);
            }}
          />
        )}
      </div>

      <Form.Content>
        <Form.Column>
          {status === CONTACT_STATE.SHOW_CONTACT_US && (
            <Form.Field
              data-qa="marketplace-app-contact-us-email"
              data-testid='marketplace-app-contact-us-email'
              disabled={isDisabled}
              label={
                <FormLabel
                  changeFields={['contact_us_email']}
                  contentI18nTag="contactUsEmail"
                  copyText={fields.contact_us_email}
                />
              }
              type="email"
              value={fields.contact_us_email}
              errors={validations.contact_us_email}
              onChange={(e) => onChange('contact_us_email', e.target.value)}
            />
          )}

          {status === CONTACT_STATE.SHOW_LEARN_HOW && (
            <Form.Field
              data-qa="marketplace-app-learn-how-url"
              data-testid='marketplace-app-learn-how-url'
              disabled={isDisabled}
              label={
                <FormLabel
                  changeFields={['learn_how_url']}
                  contentI18nTag="learnHowUrl"
                  copyText={fields.learn_how_url}
                />
              }
              type="text"
              value={fields.learn_how_url}
              errors={validations.learn_how_url}
              onChange={(e) => onChange('learn_how_url', e.target.value)}
            />
          )}

          {(status === CONTACT_STATE.CREATE_LEAD) && (
            <div data-testid='marketplace-app-create-lead-display'>
              <p>
                <br />
                {I18n.t('labels.marketplaceApp.info.createLeadNote')}
                <br />
                {I18n.t('labels.marketplaceApp.info.createLeadSecondNote')}
              </p>
            </div>
          )}
        </Form.Column>
      </Form.Content>
    </div>
  );
};

TabContact.propTypes = {
  I18n: PT.shape({}).isRequired,
  fields: PT.shape({}).isRequired,
  publishedFields: PT.shape({}).isRequired,
  validations: PT.shape({}).isRequired,
  isDisabled: PT.bool.isRequired,
  isProcoreEmployee: PT.bool.isRequired,
  onFieldChange: PT.func.isRequired,
  hasChanged: PT.func.isRequired,
};

export default TabContact;
